import React, { useState } from "react";
import { VolunteerEntry } from "../../routes/attendanceRecord";
import "./generalInfo.css";

interface GeneralInfoProps {
  volunteers: VolunteerEntry[];
}

interface Volunteer {
  name: string;
  position: string;
  engine: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ volunteers }) => {
  const [inCharge, setInCharge] = useState<Volunteer>({
    name: "",
    position: "",
    engine: "",
  });

  const [inChargeEngine, setInChargeEngine] = useState<Volunteer>({
    name: "",
    position: "",
    engine: "",
  });

  const [isUnmaned, setIsUnmaned] = useState(false);

  // Function to update one or more volunteer attributes
  const updateInCharge = (newData: Partial<Volunteer>) => {
    setInCharge((prevInCharge) => ({
      ...prevInCharge,
      ...newData,
    }));
  };

  // Function to update one or more volunteer attributes
  const blurIncharge = (newData: Partial<Volunteer>) => {
    if (newData.name == "" && isUnmaned) {
      newData.name = "No aplica 6-1";
      // newData.engine = "N/A";
      setInCharge((prevInCharge) => ({
        ...prevInCharge,
        ...newData,
      }));
    }
  };

  const updateInChargeEngine = (newData: Partial<Volunteer>) => {
    setInChargeEngine((prevInCharge) => ({
      ...prevInCharge,
      ...newData,
    }));
  };

  // Handle the unmanned checkbox toggle
  const handleUnmanedCheckboxChange = () => {
    console.log(isUnmaned);
    if (!isUnmaned) {
      setInCharge((prevInCharge) => ({
        ...prevInCharge,
        ...{ name: "No aplica 6-1", engine: "N/A", position: "" },
      }));

      setInChargeEngine((prevInCharge) => ({
        ...prevInCharge,
        ...{ name: "6-1", engine: "N/A", position: "" },
      }));
    }
    setIsUnmaned((prevChecked) => !prevChecked);
  };

  return (
    <div className="grid grid-container">
      <div>
        <h3>Información de Mando</h3>
        <form>
          <fieldset>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={isUnmaned}
                  onChange={handleUnmanedCheckboxChange}
                />
                6-1 (Acude a emergencia solo conductor)
              </label>
            </div>

            <div className="grid">
              <div>
                <label>
                  Al mando del cuerpo
                  {isUnmaned ? (
                    <input
                      name="in_charge"
                      placeholder="No aplica, 6-1"
                      value={inCharge.name}
                      onChange={(e) => updateInCharge({ name: e.target.value })}
                      onBlur={(e) => blurIncharge({ name: e.target.value })}
                    />
                  ) : inCharge.engine === "14" ? (
                    <select
                      name="in_charge_engine"
                      aria-label="Seleccione una opción"
                      required
                      onChange={(e) => updateInCharge({ name: e.target.value })}
                    >
                      {volunteers.map(
                        (vol, index) =>
                          vol.checked && (
                            <option key={index}>
                              {vol.name +
                                (vol.position ? ` (${vol.position})` : "")}
                            </option>
                          )
                      )}
                    </select>
                  ) : (
                    <input
                      name="in_charge"
                      placeholder="Nombre del mando"
                      onChange={(e) => updateInCharge({ name: e.target.value })}
                    />
                  )}
                </label>

                <label>
                  A cargo de la compañía
                  <select
                    name="in_charge_engine"
                    aria-label="Seleccione una opción"
                    required
                    disabled={isUnmaned}
                    onChange={(e) =>
                      updateInChargeEngine({ name: e.target.value })
                    }
                  >
                    {volunteers.map(
                      (vol, index) =>
                        vol.checked && (
                          <option
                            key={index + 1}
                            selected={vol.name == inChargeEngine.name}
                          >
                            {vol.name +
                              (vol.position ? ` (${vol.position})` : "")}
                          </option>
                        )
                    )}
                    <option
                      key="0"
                      value={"6-1"}
                      selected={isUnmaned || "6-1" == inChargeEngine.name}
                    >
                      6-1
                    </option>
                  </select>
                </label>
              </div>

              <div>
                <label>
                  Compañía
                  <select
                    name="company"
                    aria-label="Seleccione una opción"
                    required
                    onChange={(e) => updateInCharge({ engine: e.target.value })}
                  >
                    {Array.from({ length: 22 }, (_, i) => i + 1).map(
                      (number, index) => (
                        <option key={index + 1} value={number.toString()}>
                          {number} Cia
                        </option>
                      )
                    )}
                    <option key="0" value={"N/A"} selected={isUnmaned}>
                      N/A
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <div className="vertical-sep"></div>

      <div>
        <h3>Informacion dirección</h3>
        <form>
          <fieldset>
            <label>
              Calle principal
              <input name="main_street" placeholder="Calle principal" />
            </label>
            <label>
              Esquina
              <input name="intersection_street" placeholder="Esquina" />
            </label>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default GeneralInfo;
