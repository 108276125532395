// Modal.tsx
import React from "react";
import "./modal.css";

interface ModalProps {
  imageSrc: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ imageSrc, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-btn" onClick={onClose}>
          &times;
        </button>
        <img src={imageSrc} alt="Full Size" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
