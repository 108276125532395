import React, { useState } from "react";
import "./volunteerList.css";
import VolunteerCheckbox from "./volunteerCheckbox";

interface volunteerListProps {
  title: string;
  volunteers: VolunteerEntry[];
  onVolunteerCheck: (checked: boolean, volunteer: VolunteerEntry) => void;
}

interface VolunteerEntry {
  id: number;
  name: string;
  position: string;
  checked: boolean;
  group: number;
}

const VolunteerList: React.FC<volunteerListProps> = ({
  title,
  volunteers,
  onVolunteerCheck,
}) => {
  return (
    <div>
      <table className="striped volunteer-table">
        <thead>
          <tr>
            <th scope="col">{title}</th>
          </tr>
        </thead>
        <tbody>
          {volunteers.map((vol) => (
            <tr key={vol.id}>
              <th scope="row">
                <VolunteerCheckbox
                  volunteer={vol}
                  onChange={onVolunteerCheck}
                />
              </th>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="row">
              Total:{" "}
              {volunteers.reduce(
                (total, vol) => total + Number(vol.checked),
                0
              )}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default VolunteerList;
