import React from "react";

interface VolunteerCheckboxProps {
  volunteer: VolunteerEntry;
  onChange: (checked: boolean, volunteer: VolunteerEntry) => void;
}

interface VolunteerEntry {
  id: number;
  name: string;
  position: string;
  checked: boolean;
  group: number;
}

const VolunteerCheckbox: React.FC<VolunteerCheckboxProps> = ({
  volunteer,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, volunteer);
  };
  return (
    <div>
      <input
        type="checkbox"
        checked={volunteer.checked}
        onChange={handleChange}
        className="vol-checkbox"
      />
      <label>
        {volunteer.name +
          (volunteer.position != "" ? " (" + volunteer.position + ")" : "")}
      </label>
    </div>
  );
};

export default VolunteerCheckbox;
