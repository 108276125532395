import React, { useCallback, useState, useRef } from "react";
import { Form } from "react-router-dom";
import VolunteerList from "../features/attendance/volunteerList";
import "./attendanceRecord.css";
import Autocomplete from "../features/attendance/autoComplete";
import {
  APIProvider,
  Map,
  ControlPosition,
  AdvancedMarker,
  MapMouseEvent,
  AdvancedMarkerProps,
  useAdvancedMarkerRef,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { CustomMapControl } from "../features/attendance/mapControl";
import MapHandler from "../features/attendance/mapHandler";
import { UndoRedoControl } from "../features/attendance/undoRedoControl";
import { useDrawingManager } from "../features/attendance/useDrawingManager";
import { PlaceAutocompleteClassic } from "../features/attendance/mapAutoComplete";
import truckicon from "../img/truck.svg";
import googlePin from "../img/googlePin.png";
import { FaRegTrashCan } from "react-icons/fa6";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Canvas from "../features/canvas/canvas";
import html2canvas from "html2canvas";
import ThumbnailShower from "../features/thumbnails/thumbnails"; // Import ThumbnailShower
import Modal from "../features/tools/modal";
import InformationForm from "../features/attendance/generalInfo";
import GeneralInfo from "../features/attendance/generalInfo";

export interface VolunteerEntry {
  id: number;
  name: string;
  position: string;
  checked: boolean;
  group: number;
}

interface AttendanceRecordProps {
  initialVolunteers: VolunteerEntry[];
}

interface Marker {
  text: string;
  type: string;
  lat: number;
  lng: number;
}

const volunteersArr = [
  {
    id: 1,
    name: "capitan",
    position: "capitan",
    checked: false,
    group: 0,
  },
  {
    id: 2,
    name: "director",
    position: "director",
    checked: false,
    group: 0,
  },
  {
    id: 3,
    name: "teniente 1",
    position: "tt1",
    checked: false,
    group: 0,
  },
  {
    id: 4,
    name: "teniente 2",
    position: "tt2",
    checked: false,
    group: 0,
  },
  {
    id: 5,
    name: "honorario",
    position: "",
    checked: false,
    group: 1,
  },
  {
    id: 6,
    name: "honorario2",
    position: "",
    checked: false,
    group: 1,
  },
  {
    id: 7,
    name: "hono3",
    position: "",
    checked: false,
    group: 1,
  },
  {
    id: 9,
    name: "activo",
    position: "",
    checked: false,
    group: 2,
  },
  {
    id: 10,
    name: "activo2",
    position: "",
    checked: false,
    group: 2,
  },
  {
    id: 11,
    name: "activo3",
    position: "",
    checked: false,
    group: 2,
  },
  {
    id: 12,
    name: "activo4",
    position: "",
    checked: false,
    group: 2,
  },
];

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
let isDragTrue = false;

const AttendanceRecord: React.FC = () => {
  const [volunteers, setVolunteers] = useState<VolunteerEntry[]>(volunteersArr);

  const [markers, setMarkers] = useState<Marker[]>([]);

  const volunteerChecked = (checked: boolean, volunteer: VolunteerEntry) => {
    // Log changes
    console.log(
      `${volunteer.name} was changed to ${checked} at index ${volunteer.id}`
    );

    const updatedVolunteers = volunteers.map((vol) =>
      vol.id === volunteer.id ? { ...vol, checked: checked } : vol
    );
    console.log(volunteers);
    setVolunteers(updatedVolunteers); // Update state
  };

  const handleVolunteerSelect = (updatedVolunteer: VolunteerEntry) => {
    console.log(updatedVolunteer, "in row");
    const updatedVolunteers = volunteers.map((vol) =>
      vol.id === updatedVolunteer.id
        ? { ...vol, checked: !updatedVolunteer.checked }
        : vol
    );
    setVolunteers(updatedVolunteers);
    console.log(updatedVolunteers);
  };

  const handleMarkerDragEnd = (
    index: number,
    event: google.maps.MapMouseEvent
  ) => {
    const newPosition = event.latLng; // Get new position after drag ends
    console.log("Drag end");
    console.log(newPosition);
    if (newPosition) {
      const lat = newPosition.lat();
      const lng = newPosition.lng();

      const text: string =
        index !== null && markers[index] ? markers[index].text : "";
      const type: string =
        index !== null && markers[index] ? markers[index].type : "";

      setMarkers((prevMarkers) =>
        prevMarkers.map(
          (m, i) => (i === index ? { text, type, lat, lng } : m) // Update only the dragged marker
        )
      );
    }

    isDragTrue = true; // Reset drag state if needed
  };

  const handleMapClick = (event: MapMouseEvent) => {
    console.log("map click");
    if (isDragTrue) {
      isDragTrue = false;
      console.log("Click while draggin");
      return;
    }
    if (infoWindowShown) {
      setInfoWindowShown(false);
      return;
    }
    console.log("continued");
    const newMarker = {
      text: "",
      type: "carro",
      lat: event.detail.latLng ? event.detail.latLng.lat : 0,
      lng: event.detail.latLng ? event.detail.latLng.lng : 0,
    };

    console.log("advanced");
    //console.log(advancedMarker);
    setInfoWindowShown(true); // Show InfoWindow

    // Open InfoWindow for the newly created marker
    const markerIndex = markers.length; // Get the index of the newly added marker
    setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
    setSelectedId(markerIndex); // Select the new marker
  };

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedMarker, setSelectedMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const onMarkerClick = useCallback(
    (id: number | null, marker?: google.maps.marker.AdvancedMarkerElement) => {
      setSelectedId(id);
      console.log(marker?.position);
      console.log(id);
      if (marker) {
        setSelectedMarker(marker);
      }

      console.log(markers[id ? id : 0]);
      if (id !== selectedId) {
        setInfoWindowShown(true);
      } else {
        setInfoWindowShown((isShown) => !isShown);
      }
    },
    [selectedId]
  );

  const handleInfowindowCloseClick = useCallback(() => {
    console.log("closing infowindow");
    setInfoWindowShown(false); // Use a semicolon instead of a comma here
  }, []);

  const handleMarkerTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number | null
  ) => {
    const type = event.target.value;
    setMarkers((prevMarkers) =>
      prevMarkers.map((m, i) => (i === index ? { ...m, type } : m))
    );
  };

  const handleDeleteMarker = () => {
    console.log("deleting marker");
    if (selectedId !== null) {
      setMarkers((prevMarkers) =>
        prevMarkers.filter((_, i) => i !== selectedId)
      ); // Remove the selected marker
      setInfoWindowShown(false); // Close InfoWindow after deletion
      setSelectedId(null); // Reset the selected marker
    }
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track active tab index

  const handleTabSelect = (index: number) => {
    setSelectedTabIndex(index); // Update selected tab index
  };

  const [thumbnails, setThumbnails] = useState<string[]>([]); // State for thumbnails

  // Take screenshot and store it as a thumbnail
  const takeScreenshot = async (): Promise<void> => {
    let elementToCapture: HTMLElement | null = null;

    if (selectedTabIndex === 0) {
      elementToCapture = document.querySelector(".gm-style") as HTMLElement;
    } else if (selectedTabIndex === 1) {
      elementToCapture = document.querySelector(
        ".konvajs-content"
      ) as HTMLElement;
    }

    if (elementToCapture) {
      try {
        const canvas = await html2canvas(elementToCapture, {
          allowTaint: true,
          useCORS: true,
        });

        const imgData = canvas.toDataURL("image/png");

        // Add new thumbnail to the list
        setThumbnails((prevThumbnails) => [...prevThumbnails, imgData]);

        // Create a link element to download the image
        /*const downloadLink = document.createElement("a");
        downloadLink.href = imgData;
        downloadLink.download = "screenshot.png";
        downloadLink.click();*/
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    } else {
      console.error("Element not found!");
    }
  };

  const handleThumbnailDelete = (index: number) => {
    const newThumbnails = thumbnails.filter((_, i) => i !== index);
    setThumbnails(newThumbnails);
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null); // State for the selected image

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  // Handle clicking on a thumbnail (open modal)
  const handleThumbnailClick = (thumbnail: string) => {
    setSelectedImage(thumbnail);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  console.log("rendering");
  return (
    <div className="container">
      <header>
        <h1>Nuevo parte de asistencia</h1>
      </header>
      <main className="container">
        <section>
          <div className="parnt">
            <Autocomplete
              suggestions={volunteers}
              onSelect={handleVolunteerSelect}
              onVolunteerCheck={volunteerChecked}
            />
          </div>
          <div className="grid">
            <VolunteerList
              title="Oficialidad"
              volunteers={volunteers.filter((vol) => vol.group == 0)}
              onVolunteerCheck={volunteerChecked}
            />
            <VolunteerList
              title="Voluntarios Honorarios"
              volunteers={volunteers.filter((vol) => vol.group == 1)}
              onVolunteerCheck={volunteerChecked}
            />
            <VolunteerList
              title="Voluntarios Activos"
              volunteers={volunteers.filter((vol) => vol.group == 2)}
              onVolunteerCheck={volunteerChecked}
            />
          </div>
        </section>
        <hr></hr>
        <section>
          <GeneralInfo volunteers={volunteers} />
        </section>
        <section>
          <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
            <TabList>
              <Tab>Croquis Ubicación</Tab>
              <Tab>Croquis Interior</Tab>
            </TabList>
            <TabPanel>
              <APIProvider apiKey={API_KEY}>
                <div className="autocomplete-control">
                  <PlaceAutocompleteClassic onPlaceSelect={setSelectedPlace} />
                </div>
                <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                  <Map
                    style={{ width: "100vw", height: "50vh" }}
                    defaultCenter={{ lat: 22.54992, lng: 0 }}
                    defaultZoom={3}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    onClick={(event) => {
                      handleMapClick(event);
                    }}
                    mapId={"cc086099b4676066"}
                  >
                    {markers.map((marker, i) => (
                      <AdvancedMarkerWithRef
                        key={i}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        draggable={true}
                        onMarkerClick={(
                          marker: google.maps.marker.AdvancedMarkerElement
                        ) => onMarkerClick(i, marker)}
                        onDragStart={() => console.log("Drag start")}
                        onDragEnd={(e: google.maps.MapMouseEvent) =>
                          handleMarkerDragEnd(i, e)
                        }
                        isSelected={i === selectedId} // Check if this marker is selected
                      >
                        <p
                          style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {marker.text}
                        </p>
                        {marker.type == "carro" ? (
                          <img src={truckicon} width={50} height={50} />
                        ) : (
                          <img src={googlePin} width={25} height={30} />
                        )}
                      </AdvancedMarkerWithRef>
                    ))}
                    {infoWindowShown && (
                      <InfoWindow
                        position={markers[selectedId ? selectedId : 0]}
                        pixelOffset={[0, -50]}
                        onCloseClick={handleInfowindowCloseClick}
                        headerContent={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h3>Marcador</h3>
                            <div
                              style={{
                                marginTop: "-2px",
                                cursor: "pointer",
                                //transition: "background-color 0.5s, transform 0.5s",
                              }}
                              onClick={handleDeleteMarker}
                              onMouseEnter={(e) => {
                                const target = e.target as HTMLDivElement; // Casting to HTMLDivElement
                                //target.style.backgroundColor = "#f0f0f0"; // Change background color on hover
                                target.style.transform = "scale(1.07)"; // Slightly scale the element on hover
                              }}
                              onMouseLeave={(e) => {
                                const target = e.target as HTMLDivElement; // Casting to HTMLDivElement
                                //target.style.backgroundColor = ""; // Reset background color
                                target.style.transform = ""; // Reset scale
                              }}
                            >
                              <FaRegTrashCan size={17} color="red" />
                            </div>
                          </div>
                        }
                      >
                        <label style={{ paddingBottom: "10px" }}>
                          Texto marcador
                        </label>
                        <input
                          type="text"
                          value={
                            markers.length > 0 && selectedId !== null
                              ? markers[selectedId].text // Using selectedId directly
                              : "" // Default to empty string if no markers or no selectedId
                          }
                          onChange={(e) => {
                            const newText = e.target.value; // Get new value from input
                            if (selectedId !== null) {
                              setMarkers((prevMarkers) =>
                                prevMarkers.map(
                                  (marker, index) =>
                                    index === selectedId
                                      ? { ...marker, text: newText }
                                      : marker // Update the text of the selected marker
                                )
                              );
                            }
                          }}
                        />
                        <label style={{ paddingBottom: "10px" }}>
                          Tipo Marcador
                        </label>
                        <div className="infowindow-options">
                          <label>
                            <input
                              type="radio"
                              value="carro"
                              checked={
                                markers.length > 0
                                  ? markers[selectedId ? selectedId : 0]
                                      .type === "carro"
                                  : false
                              }
                              onChange={(event) =>
                                handleMarkerTypeChange(event, selectedId)
                              }
                            />
                            <img src={truckicon} width={50} height={50} />
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="pin"
                              checked={
                                markers.length > 0
                                  ? markers[selectedId ? selectedId : 0]
                                      .type === "pin"
                                  : false
                              }
                              onChange={(event) =>
                                handleMarkerTypeChange(event, selectedId)
                              }
                            />
                            <img src={googlePin} width={30} height={30} />
                          </label>
                        </div>
                      </InfoWindow>
                    )}
                  </Map>

                  <MapHandler place={selectedPlace} />
                </div>
              </APIProvider>
            </TabPanel>
            <TabPanel>
              <Canvas></Canvas>
            </TabPanel>
          </Tabs>
          <button onClick={takeScreenshot}>Take Screenshot</button>
          <ThumbnailShower
            thumbnails={thumbnails}
            onDelete={handleThumbnailDelete}
            onThumbnailClick={handleThumbnailClick} // Pass the function to handle click
          />
        </section>
        {/* Display the modal if it's open */}
        {isModalOpen && selectedImage && (
          <Modal imageSrc={selectedImage} onClose={closeModal} />
        )}
      </main>
    </div>
  );
};

export default AttendanceRecord;

export const AdvancedMarkerWithRef = (
  props: AdvancedMarkerProps & {
    onMarkerClick: (marker: google.maps.marker.AdvancedMarkerElement) => void;
    isSelected: boolean; // Pass in whether this marker is selected
  }
) => {
  const { children, onMarkerClick, isSelected, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      onClick={() => {
        if (marker) {
          onMarkerClick(marker);
        }
      }}
      ref={markerRef}
      {...advancedMarkerProps}
    >
      {/* Conditionally show InfoWindow only if marker is selected */}
      {children}
    </AdvancedMarker>
  );
};
