import React, { useState, useRef } from "react";
import "@picocss/pico"; // Import Pico CSS for styling
import "./autoComplete.css";
import VolunteerCheckbox from "./volunteerCheckbox";

interface VolunteerEntry {
  id: number;
  name: string;
  position: string;
  checked: boolean;
  group: number;
}

interface AutocompleteProps {
  suggestions: VolunteerEntry[];
  onSelect: (volunteer: VolunteerEntry) => void;
  onVolunteerCheck: (checked: boolean, volunteer: VolunteerEntry) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  suggestions,
  onSelect,
  onVolunteerCheck,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const filteredSuggestions = suggestions.filter((vol) =>
    vol.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const resetInput = (event: React.FocusEvent<HTMLInputElement>) => {
    // Only hide suggestions if the next focus is outside the suggestion list
    console.log(event.relatedTarget?.classList);
    console.log(event);
    console.log("perro");
    if (
      !event.relatedTarget ||
      !(
        event.relatedTarget.classList.contains("suggestions-list") ||
        event.relatedTarget.classList.contains("vol-checkbox")
      )
    ) {
      setShowSuggestions(false);
      setSearchTerm("");
    } else {
      console.log("preventing");

      if (inputRef.current) {
        inputRef.current.focus(); // Keep input focused
      }
      event.preventDefault();
    }
  };

  return (
    <div className="autocomplete-container-vol">
      <input
        ref={inputRef}
        type="text"
        placeholder="Search volunteer..."
        value={searchTerm}
        onChange={handleChange}
        className="autocomplete-input"
        aria-label="Search"
        onBlur={resetInput}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list" tabIndex={0}>
          {filteredSuggestions.map((volunteer) => (
            <li
              className="suggestion-item"
              key={volunteer.id}
              onClick={(e) => {
                console.log(e);
                console.log("selected");
                onSelect(volunteer);
              }}
            >
              <VolunteerCheckbox
                volunteer={volunteer}
                onChange={(checked) => ""}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
