import React, { useState, useRef, useLayoutEffect } from "react";
import { Stage, Layer, Text, Transformer, Rect } from "react-konva";

const EditableTextCanvas: React.FC = () => {
  const [texts, setTexts] = useState<any[]>([]);
  const [rectangles, setRectangles] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const stageRef = useRef<any>(null);
  const layerRef = useRef<any>(null);
  const transformerRef = useRef<any>(null);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null); // Reference for the parent container
  const [canvasWidth, setCanvasWidth] = useState(0);

  // Set the canvas width to the parent container's width
  useLayoutEffect(() => {
    if (canvasContainerRef.current) {
      setCanvasWidth(canvasContainerRef.current.offsetWidth);
    }
    const handleResize = () => {
      if (canvasContainerRef.current) {
        setCanvasWidth(canvasContainerRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle adding new text
  const handleDoubleClick = (id: string, text: string) => {
    setSelectedId(id);
    setInputText(text);
    setIsEditing(true);
  };

  const handleChangeText = () => {
    setTexts(
      texts.map((t) => (t.id === selectedId ? { ...t, text: inputText } : t))
    );
    setIsEditing(false);
    setSelectedId(null);
  };

  const handleDragEnd = (e: any, id: string) => {
    const { x, y } = e.target.position();
    setTexts(texts.map((t) => (t.id === id ? { ...t, x, y } : t)));
  };

  const handleRectangleDragEnd = (e: any, id: string) => {
    const { x, y } = e.target.position();
    setRectangles(
      rectangles.map((rect) => (rect.id === id ? { ...rect, x, y } : rect))
    );
  };

  const handleRectangleResize = (e: any, id: string) => {
    const rect = e.target;
    setRectangles(
      rectangles.map((r) =>
        r.id === id ? { ...r, width: rect.width(), height: rect.height() } : r
      )
    );
  };

  // Update Transformer to apply to selected shape
  React.useEffect(() => {
    if (selectedId) {
      // Attach transformer to selected node
      const selectedNode = layerRef.current.findOne(`#${selectedId}`);
      transformerRef.current.nodes([selectedNode]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedId]);

  const getTextPosition = () => {
    if (selectedId && stageRef.current) {
      const textNode = layerRef.current.findOne(`#${selectedId}`);
      if (textNode) {
        return textNode.getClientRect();
      }
    }
    return { x: 0, y: 0, width: 0, height: 0 };
  };

  // Add new rectangle
  const addRectangle = () => {
    const newRect = {
      id: `rect${rectangles.length + 1}`,
      x: 50,
      y: 150,
      width: 100,
      height: 60,
      fill: "transparent",
      stroke: "black",
      strokeWidth: 2,
    };
    setRectangles([...rectangles, newRect]);
  };

  // Handle click on empty space to deselect shape
  const handleStageClick = (e: any) => {
    // Check if clicked on an empty space
    if (e.target === e.target.getStage()) {
      setSelectedId(null);
    }
  };

  return (
    <div
      ref={canvasContainerRef}
      style={{
        width: "100%",
        height: "800px", // Fixed canvas height of 800px
        position: "relative",
        overflow: "hidden",
        border: "2px solid black", // Add border to the canvas container
        margin: "0 auto", // Center the canvas horizontally
      }}
    >
      <button
        onClick={() =>
          setTexts([
            ...texts,
            {
              id: `text${texts.length + 1}`,
              text: "New text",
              x: 50,
              y: 50,
              fontSize: 20,
            },
          ])
        }
      >
        Add Text
      </button>
      <button onClick={addRectangle}>Add Rectangle</button>

      <Stage
        width={canvasWidth}
        height={800} // Fixed canvas height of 800px
        ref={stageRef}
        onClick={handleStageClick} // Add click listener to the Stage
      >
        <Layer ref={layerRef}>
          {texts.map((textObj) => (
            <Text
              key={textObj.id}
              id={textObj.id}
              text={textObj.text}
              x={textObj.x}
              y={textObj.y}
              fontSize={textObj.fontSize}
              draggable
              onDblClick={() => handleDoubleClick(textObj.id, textObj.text)}
              onDragEnd={(e) => handleDragEnd(e, textObj.id)}
              onClick={() => setSelectedId(textObj.id)}
            />
          ))}

          {rectangles.map((rectObj) => (
            <Rect
              key={rectObj.id}
              id={rectObj.id}
              x={rectObj.x}
              y={rectObj.y}
              width={rectObj.width}
              height={rectObj.height}
              fill={rectObj.fill}
              stroke={rectObj.stroke}
              strokeWidth={rectObj.strokeWidth}
              draggable
              onDragEnd={(e) => handleRectangleDragEnd(e, rectObj.id)}
              onTransformEnd={(e) => handleRectangleResize(e, rectObj.id)} // Resize handler
              onClick={() => setSelectedId(rectObj.id)} // Select the rectangle
            />
          ))}

          <Transformer
            ref={transformerRef}
            // Disable keeping aspect ratio
            keepRatio={false}
          />
        </Layer>
      </Stage>

      {isEditing && (
        <input
          ref={inputRef}
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onBlur={handleChangeText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleChangeText();
            }
          }}
          style={{
            position: "absolute", // Absolute position inside the canvas
            top: `${getTextPosition().y}px`, // Position based on the selected text node
            left: `${getTextPosition().x}px`, // Position based on the selected text node
            width: `${getTextPosition().width}px`, // Match the width of the text
            fontSize: `${20}px`,
            padding: "4px",
            outline: "none",
            background: "transparent",
            border: "1px solid #ddd",
            resize: "none",
            lineHeight: "1.2",
            fontFamily: "Arial",
          }}
        />
      )}
    </div>
  );
};

export default EditableTextCanvas;
