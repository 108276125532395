import "./thumbnails.css";

interface ThumbnailShowerProps {
  thumbnails: string[]; // List of thumbnail URLs
  onDelete: (index: number) => void; // Function to handle deletion
  onThumbnailClick: (thumbnail: string) => void; // Function to handle thumbnail click
}

const ThumbnailShower: React.FC<ThumbnailShowerProps> = ({
  thumbnails,
  onDelete,
  onThumbnailClick,
}) => {
  const handleDownload = (thumbnail: string) => {
    const link = document.createElement("a");
    link.href = thumbnail;
    link.download = thumbnail.split("/").pop() || "image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="thumbnail-shower">
      <div className="thumbnail-row">
        {thumbnails.map((thumbnail, index) => (
          <div key={index} className="thumbnail-item">
            <div className="thumbnail">
              <img
                src={thumbnail}
                alt="Thumbnail"
                className="thumb-image"
                onClick={() => onThumbnailClick(thumbnail)}
              />
              <button onClick={() => onDelete(index)} className="delete-btn">
                X
              </button>
              <button
                onClick={() => handleDownload(thumbnail)}
                className="download-btn"
              >
                ↓
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThumbnailShower;
