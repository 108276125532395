import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import AttendanceRecord from "./routes/attendanceRecord";
//import "./pico.scss";
const volunteers = [
  {
    id: 1,
    name: "seba",
    position: "capitan",
    checked: false,
  },
  {
    id: 2,
    name: "ignacio",
    position: "director",
    checked: false,
  },
  {
    id: 3,
    name: "seba2",
    position: "tt1",
    checked: false,
  },
  {
    id: 4,
    name: "ignaseba3cio",
    position: "tt2",
    checked: false,
  },
];

const volunteersNormal = [
  {
    id: 1,
    name: "seba",
    position: "capitan",
    checked: false,
  },
  {
    id: 2,
    name: "ignacio",
    position: "director",
    checked: false,
  },
  {
    id: 3,
    name: "seba2",
    position: "tt1",
    checked: false,
  },
  {
    id: 4,
    name: "ignaseba3cio",
    position: "tt2",
    checked: false,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "attendanceRecord",
    element: <AttendanceRecord />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
